import BaseRenderMixin from "@/components/base/BaseRenderMixin";
import {is_empty} from "../utils";

export default {
    name: "BaseSearchComponent",
    mixins: [BaseRenderMixin],
    methods: {
        make_element_tree(createElement, element_data, options = {}) {
            let data = {}
            let el, props, attrs, style, children

            [el, props] = this.get_el_props(element_data)
            attrs = this.get_attrs(element_data)
            children = this.get_children(element_data, createElement)
            style = this.get_style(element_data)

            if (props) data.props = props
            if (attrs) {
                if (this.$store.state.filter && this.$store.state.filter.search) attrs.value = this.$store.state.filter.search
                data.attrs = attrs
            }
            if (style) data.style = style

            data.on = {
                keyup: this.on_search,
            }

            for (let key in options) data[key] = options[key]

            return createElement(
                el,
                data,
                children
            )
        },
        get_children(element_data, createElement) {
            let children = []
            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {

                        if (item.tag === "input{" && item.attr && item.attr.href) {
                            if (item.attr.href.includes("bootstrap")) continue;
                        }

                        children.push(this.make_element_tree(createElement, item));
                    } else if (item.node === "text") {
                        children.push(item.text);
                    }
                }
            }
            return children
        },
        on_search(event) {            
            if (event.key === 'Enter' || event.keyCode === 13) {
                // this.$store.state.filter.search = event.target.value
                if (!event.target.value) return

                if (window.location.pathname !== '/shop') {
                    window.location.href = window.location.origin + '/shop#search=' + event.target.value
                } else {
                    let url_object = new URL(document.URL)
                    let hash = url_object.hash.substr(1)
                    let filter_object = {}
                    if (!is_empty(hash)) {
                        filter_object = hash
                            .split('&')
                            .reduce((result, item) => {
                                let parts = item.split('=')
                                result[parts[0]] = parts[1]
                                return result
                            }, {});
                    }
                    filter_object['search'] = event.target.value

                    url_object.hash = `#${new URLSearchParams(filter_object).toString()}`
                    window.location.href = url_object.href
                }

            }
        }
    },
}
